const ANOMALY_LAYER_ID = 'anomaly-vector-l'
const HIGHLIGHT_LAYER_ID = 'highlight-mmsi'
const ANOMALY_SOURCE_ID = 'anomaly-vector-source'
const MMSI_SOURCE_ID = 'mmsi-vector-source'
const EXCLUSION_SOURCE_ID = 'exclusion-source';
const EXCLUSION_LAYER_ID = 'exclusion-layer';
const INCIDENT_COLOR = '#f65d4c'
const ANOMALY_COLOR = '#E19C35'
const HIGHLIGHT_COLOR = '#808080'
const EXCLUSION_COLOR = '#404040'


const MapService = {
    getIncidentColor: () => {
        return INCIDENT_COLOR
    },
    getAnomalyLayerId: () => {
        return ANOMALY_LAYER_ID
    },
    getHighlightLayerId: () => {
        return HIGHLIGHT_LAYER_ID
    },
    getFishingZoneSource: () => {
        const source = {
            'type': 'raster',
            'tiles': [ 'https://wms.geonorge.no/skwms1/wms.nmg?BBOX={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Maritime_omraader,Maritime_grenser'
            ],
            'tileSize': 256,
            'attribution': '<a target="_top" rel="noopener" href="https://kartverket.no/">Kartverket</a>'
        }
        return source
    },
    getFishingZoneLayer: (sourceId) => {
        const layer = {
            'id': 'fiskerigrense',
            'type': 'raster',
            'source': sourceId,
            'minzoom': 0,
            'maxzoom': 22
        }
        return layer
    },
    getKartverketTopoSource: () => {
        const source = {
            'type': 'raster',
            'tiles': [
                'https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png'
            ],
            'tileSize': 256,
            'attribution':
                '<a target="_top" rel="noopener" href="https://kartverket.no/">Kartverket</a>'
        }
        return source
    },
    getKartverketTopoLayer: (sourceId) => {
        const layer = {
            'id': 'topo4',
            'type': 'raster',
            'source': sourceId,
            'minzoom': 0,
            'maxzoom': 22
        }
        return layer
    },
    getAnomalyLayer: (sourceId) => {
        const layer = {
            'id': ANOMALY_LAYER_ID,
            'type': 'circle',
            'source': sourceId,
            'paint': {
                'circle-color': ANOMALY_COLOR,
                'circle-radius': 5.0,
                'circle-opacity': 0.3
            }
        }
        return layer
    },
    getAnomalySource: () => {
        return ANOMALY_SOURCE_ID
    },
    getMmsiSource: () => {
        return MMSI_SOURCE_ID
    },
    getExclusionSource: () => {
        return EXCLUSION_SOURCE_ID;
    },
    getExclusionLayerId: () => {
        return EXCLUSION_LAYER_ID;
    },
    getExclusionColor: () => {
        return EXCLUSION_COLOR;
    },
    getHighlightLayer: (sourceId) => {
        const layer = {
            'id': HIGHLIGHT_LAYER_ID,
            'type': 'circle',
            'source': sourceId,
            'paint': {
                'circle-stroke-color': HIGHLIGHT_COLOR,
                'circle-radius': 5.0,
                'circle-opacity': 0.0,
                'circle-stroke-width': 2
            },
            'filter':  [
                'any',
                ['all', ['==', 'mmsi', 0]]
            ]
        }
        return layer
    },
    getHighlightLayerWithoutFilter: (sourceId) => {
        const layer = {
            'id': HIGHLIGHT_LAYER_ID,
            'type': 'circle',
            'source': sourceId,
            'paint': {
                'circle-stroke-color': HIGHLIGHT_COLOR,
                'circle-radius': 5.0,
                'circle-opacity': 0.0,
                'circle-stroke-width': 2
            }
        }
        return layer
    },
    getExclusionLayer: (sourceId) => {
        const layer = {
                'id': EXCLUSION_LAYER_ID,
                'type': 'fill',
                'source': sourceId,
                'minzoom': 5,
                'maxzoom': 22,
                'paint': {
                    'fill-color': EXCLUSION_COLOR,
                    'fill-opacity': 0.8
                }
        }
        return layer
    }
}
export default MapService